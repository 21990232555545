// formatting options for human-readable dates
const date_options = { 
    year:   'numeric',
    month:  'short',
    day:    'numeric',
    hour:   'numeric', 
    minute: 'numeric' 
};

// FIXME: is hardcoding 'en-US' okay?
const date_fmt = new Intl.DateTimeFormat('en-US', date_options);

const FormatDate = function(d){
    return date_fmt.format(new Date(d))
}

export default FormatDate;