<template>
  <div>

  <h2 class="text-2xl leading-6 font-medium text-gray-900">
    Domain Overview
  </h2>

  <div class="mt-3 gap-5 xl:gap-7 grid grid-cols-1 md:grid-cols-2">

    <Card
      v-if="this.num_findings.phishing && this.num_findings.phishing.TOTAL > 0"
      title="Phishing"
      page="/domain/phishing"
      :newFindings     ="num_findings.phishing.NEW"
      :openFindings    ="num_findings.phishing.OPEN"
      :resolvedFindings="num_findings.phishing.RESOLVED"
      :totalFindings   ="num_findings.phishing.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="this.num_findings.malware && this.num_findings.malware.TOTAL > 0"
      title="Malward"
      page="/domain/malware"
      :newFindings     ="num_findings.malware.NEW"
      :openFindings    ="num_findings.malware.OPEN"
      :resolvedFindings="num_findings.malware.RESOLVED"
      :totalFindings   ="num_findings.malware.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="this.num_findings.cred_harvesting && this.num_findings.cred_harvesting.TOTAL > 0"
      title="Credential Harvesting"
      page="/domain/cred-harvesting"
      :newFindings     ="num_findings.cred_harvesting.NEW"
      :openFindings    ="num_findings.cred_harvesting.OPEN"
      :resolvedFindings="num_findings.cred_harvesting.RESOLVED"
      :totalFindings   ="num_findings.cred_harvesting.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="this.num_findings.parked && this.num_findings.parked.TOTAL > 0"
      title="Parked"
      page="/domain/parked"
      :newFindings     ="num_findings.parked.NEW"
      :openFindings    ="num_findings.parked.OPEN"
      :resolvedFindings="num_findings.parked.RESOLVED"
      :totalFindings   ="num_findings.parked.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="this.num_findings.benign && this.num_findings.benign.TOTAL > 0"
      title="Benign"
      page="/domain/benign"
      :newFindings     ="num_findings.benign.NEW"
      :openFindings    ="num_findings.benign.OPEN"
      :resolvedFindings="num_findings.benign.RESOLVED"
      :totalFindings   ="num_findings.benign.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="this.num_findings.other && this.num_findings.other.TOTAL > 0"
      title="Other"
      page="/domain/other"
      :newFindings     ="num_findings.other.NEW"
      :openFindings    ="num_findings.other.OPEN"
      :resolvedFindings="num_findings.other.RESOLVED"
      :totalFindings   ="num_findings.other.TOTAL"
      :jsonError       ="jsonError"
    />
  </div>
</div>
</template>

<script>
import Card from '@/components/pages/Dashboard/Card.vue';

export default {
  components: {
    Card,
  },
  props: {
    num_findings: Object,
    jsonError: Boolean,
  },
};
</script>