<template>
  <div>
    <!-- ------------------------------------------ -->
    <!-- Finding content -->

    <!-- finding details -->
    <div v-if="finding !== null" class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">

      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-3xl leading-6 font-medium text-gray-900">
            Finding Details
          </h3>
          <p class="mt-2 max-w-2xl text-md text-gray-500">
            last updated: {{ format_date(finding.last_updated) }}
          </p>
        </div>

        <!-- descriptors common to all findings -->
        <div class="border-t border-gray-200 divide-y divide-gray-200">
          <dl class="divide-y divide-gray-200">
            <div
              class="py-4 px-6 grid grid-cols-3 gap-4"
              :class="this.colors.row[finding.finding_status]"
            >
              <dt class="text-md font-medium text-gray-500">
                Status
              </dt>
              <dd
                class="text-md text-gray-900 sm:col-span-2 font-semibold"
                :class="this.colors.text[finding.finding_status]"
              >
                {{ this.statuses[finding.finding_status] }}
              </dd>
            </div>

            <div class="py-4 px-6 grid grid-cols-3 gap-4">
              <dt class="text-md font-medium text-gray-500">
                Category
              </dt>
              <dd class="text-md text-gray-900 sm:col-span-2">
                {{ this.userStrings[finding.finding_type].feature }}
              </dd>
            </div>

            <div class="py-4 px-6 grid grid-cols-3 gap-4">
              <dt class="text-md font-medium text-gray-500">
                Source
              </dt>
              <dd class="text-md text-gray-900 sm:col-span-2">
                {{ this.userStrings[finding.finding_type].source }}
              </dd>
            </div>
          </dl>

          <!-- feature-specific descriptors  -->
          <GitHubFinding 
            v-if="finding.finding_type === 'GITHUB'"
            class="divide-y divide-gray-200"
            :finding="finding"
          />

          <DeHashedFinding
            v-if="finding.finding_type === 'DEHASHED'"
            class="divide-y divide-gray-200"
            :finding="finding"
          />

          <DNSTwistFinding
            v-if="finding.finding_type === 'DNSTWIST'"
            class="divide-y divide-gray-200"
            :finding="finding"
            :formatDate="format_date"
          />

          <FootprintFinding 
            v-if="finding.finding_type ==='FOOTPRINT'"
            class="divide-y divide-gray-200"
            :finding="finding"
          />
        </div>
      </div>
    </div>
          
    <!-- Spinner UI for loading -->
    <div v-else class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
      <div class="bg-white shadow overflow-hidden sm:rounded-lg">
        <div class="px-4 py-5 sm:px-6">
          <h3 class="text-3xl leading-6 font-medium text-gray-900">
            Finding Details
          </h3>
          <p
            :class="this.$store.state.account.appErrorHeading !== null ? 'text-red-400' : 'text-gray-400'" 
            class="mt-6 max-w-2xl text-md"
          >
            <font-awesome-icon
              :icon="['fas', 'spinner']"
              class="text-xl animate-spin-pulse"
            />            
            <span class="ml-4">loading...</span>
          </p>
        </div>
      </div>
    </div>

    <!-- end Finding Content -->
    <!-- ------------------------------------------ -->
  </div>
</template>

<script>
import GitHubFinding    from '@/components/pages/Finding/GitHubFinding.vue';
import DeHashedFinding  from '@/components/pages/Finding/DeHashedFinding.vue';
import DNSTwistFinding  from '@/components/pages/Finding/DNSTwistFinding.vue';
import FootprintFinding from '@/components/pages/Finding/FootprintFinding.vue';

// import json from '@/mock_data/response.json';
import { fetchMessage } from '@/services/fetchers';
import FormatDate  from '@/services/DateFormat.js';
import userStrings from '@/strings/en.js';
import { EXTERNAL_API_URL_BASE } from '@/aws-config.js';

export default {
  components: {
    GitHubFinding, DeHashedFinding, DNSTwistFinding, FootprintFinding,
  },

  data() {
    return {
      // data unique to this Finding
      finding: null,
      
      colors: {
        row: { NEW: 'bg-red-50', OPEN: 'bg-yellow-50', RESOLVED: 'bg-green-50', },
        text: { NEW: 'text-red-800', OPEN: 'text-yellow-800', RESOLVED: 'text-green-800', },
      },

      // FIXME: abstract these strings out to a shared language file
      statuses: { NEW: 'New', OPEN: 'Open',   RESOLVED: 'Resolved' },
      userStrings: userStrings,
    };
  },
  async created() {
    try {
      const json = await fetchMessage(`${EXTERNAL_API_URL_BASE}findings`);

      for (const finding of json) {
        if (finding.finding_id === this.$route.params.id) {
          this.finding = finding;
          this.$store.dispatch(
            'ui/updateActiveFeature',
            this.userStrings[finding.finding_type].activeFeature
          );
          break;
        }
      }
    } catch (error) {
      this.$store.dispatch('account/setAppError', {
        appErrorHeading: 'There was an error retrieving your findings; try refreshing the page.  If the problem persists, let us know.',
        appErrorMessage: null
      });
    }
  },
  methods: {
    format_date: FormatDate,
  },
};
</script>