<template>
  <div v-if="visible" class="lg:hidden">
    <div class="fixed inset-0 flex z-40">
      <div class="fixed inset-0" aria-hidden="true">
        <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
      </div>
      <div
        class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800"
      >
        <!-- close mobile menu -->
        <div class="absolute top-0 right-0 -mr-12 pt-2">
          <button
            @click="this.$emit('menuToggled')"
            class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          >
            <span class="sr-only">Close sidebar</span>
            <!-- Heroicon name: outline/x -->
            <svg
              class="h-6 w-6 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <!-- SIFTR logo -->
        <div class="flex-shrink-0 flex items-center px-4">
          <img
            class="w-2/3 h-2/3 object-scale-down"
            src="/siftr-logo.png"
            alt="SIFTR logo"
          />     
        </div>
        
        <!-- mobile nav -->
        <div class="mt-1 flex-1 h-0 overflow-y-auto">
          <SidebarNav @menuToggled="this.$emit('menuToggled')" />
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import SidebarNav from "@/components/_common/SidebarNav.vue"

export default {
  components:{
    SidebarNav
  },
  props: {
    visible: Boolean,
  },
  emits: ["menuToggled"],
}
</script>

