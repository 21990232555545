<template>
  <div class="mt-8">
    <h1 class="text-2xl leading-6 font-medium text-gray-900">
      Perimeter - All Findings
    </h1>

    <div class="overflow-x-auto">
      <table class="table-auto min-w-full mt-3 border-2 border-collapse shadow rounded-lg">
        <thead>
          <tr
            class="
              border-t border-b border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              uppercase
              text-left text-sm
              tracking-wider
            "
          >
            <th class="px-4 md:px-5 py-3 font-medium">
              <!-- hide "Details" text for xs width, but keep the th element -->
              <span class="hidden sm:table-cell"> Details </span>
            </th>

            <th
              @click="sort('finding.description', this.direction, this.findings)"
              class="px-4 md:px-5 xl:px-6 py-3 whitespace-nowrap font-medium cursor-pointer hover:bg-gray-200"
            >
              Description
              <font-awesome-icon 
                :icon="['fas', (this.direction['finding.description'] || 'sort')]"
                :class="this.direction['finding.description'] !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2"
              /> 
            </th>

            <th
              @click="sort('finding_status', this.direction, this.findings)"
              class="px-4 md:px-5 xl:px-6 py-3 whitespace-nowrap font-medium cursor-pointer hover:bg-gray-200"
            >
              Status
              <font-awesome-icon 
                :icon="['fas', (this.direction.finding_status || 'sort')]"
                :class="this.direction.finding_status !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2"
              /> 
            </th>

            <th
              @click="sort('last_updated', this.direction, this.findings)"
              class="hidden md:table-cell px-6 py-3 whitespace-nowrap font-medium cursor-pointer hover:bg-gray-200"
            >
              Updated
              <font-awesome-icon 
                :icon="['fas', (this.direction.last_updated || 'sort')]"
                :class="this.direction.last_updated !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2"
              /> 
            </th>

            <th class="px-4 md:px-5 xl:px-6 py-3 font-medium">Findings</th>
          </tr>
        </thead>
        <tbody
          v-if="this.findings !== null && this.findings.length > 0"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            v-for="finding in this.findings"
            :key="finding.id"
            :class="this.colors.tr[finding.finding_status]"
            class="border-t border-gray-300"
          >
            <!-- link to full findings -->
            <td
              class="border-l-8 text-gray-900"
              :class="this.colors.td[finding.finding_status]"
            >
              <router-link
                :to="'/findings/' + finding.finding_id"
                class="pl-10 pr-6 py-5 block w-auto h-auto"
              >
                <font-awesome-icon :icon="['fas', 'arrow-circle-right']" />
              </router-link>
            </td>

            <!-- finding source -->
            <td class="px-4 md:px-5 xl:px-6">
              <div class="flex items-center space-x-2">
                <div class="flex flex-shrink-0 space-x-1">
                  {{ finding.finding.description }}
                </div>
              </div>
            </td>

            <!-- finding status -->
            <td class="px-4 md:px-5 xl:px-6">
              <div class="flex items-center space-x-2">
                <div
                  class="flex flex-shrink-0 space-x-1 font-semibold"
                  :class="this.colors.text[finding.finding_status]"
                >
                  {{ this.statuses[finding.finding_status] }}
                </div>
              </div>
            </td>

            <!-- last updated -->
            <td class="hidden md:table-cell px-6">
              {{ finding.last_updated }}
            </td>

            <!-- link to download xlsx  -->
            <td
              @click="downloadFinding(finding.finding_id, 'xlsx')"
              :class="this.colors.td[finding.finding_status]"
              class="md:pl-5 xl:pl-6 cursor-pointer"
            >
              <span class="text-grey-800 py-5 block w-auto h-auto">
                <font-awesome-icon :icon="['fas', 'file-download']" />
                <span class="px-1"> Excel </span>
              </span>
            </td>
          </tr>
        </tbody>

        <tbody
          v-else-if="this.findings !== null && this.findings.length === 0"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            class="
              border-t border-b border-r border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              text-left text-sm
              tracking-wider
            "
          >
            <td colspan="5" class="px-4 xl:px-6 py-3">(no findings)</td>
          </tr>
        </tbody>

        <tbody
          v-else-if="this.findings === null"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            class="
              border-t border-b border-r border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              text-left text-sm
              tracking-wider
            "
          >
            <td colspan="5" class="px-4 xl:px-6 py-3">
              <font-awesome-icon
                :icon="['fas', 'spinner']"
                :class="jsonError === true ? 'text-red-400' : 'text-gray-400'"
                class="text-xl animate-spin-pulse"
              />
              <span class="ml-4 text-gray-400">loading...</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SortTable from '@/services/TableSort.js';
import { downloadFinding } from '@/services/fetchers';

export default {
  data() {
    return {
      direction: { ['finding.description']: null, finding_status: null, last_updated: 'sort-down' },
      statuses: { NEW: 'New', OPEN: 'Open', RESOLVED: 'Resolved' },
      colors: {
        tr: { NEW: 'bg-red-50', OPEN: 'bg-yellow-50', RESOLVED: 'bg-green-50', },
        td: { NEW: 'border-red-300 hover:bg-red-100', OPEN: 'border-yellow-300 hover:bg-yellow-100', RESOLVED: 'border-green-300 hover:bg-green-100', },
        text: { NEW: 'text-red-800', OPEN: 'text-yellow-800', RESOLVED: 'text-green-800', },
      },
    };
  },
  props: {
    findings: Array,
    jsonError: Boolean,
  },
  methods: {
    sort: SortTable,
    downloadFinding: downloadFinding,
  },
   created(){
   this.sort('last_updated', this.direction, this.findings)
  }

};
</script>