<template>
  <div class="bg-white overflow-hidden shadow rounded-lg">
    <div class="p-5 flex items-stretch">
      <!-- number of new findings; full height, left side of card -->
      <div class="ml-4 grid grid-cols-1">
        <div 
          class="text-5xl text-center"
          :class="[newFindings > 0 ? 'text-black font-semibold' : 'text-gray-500']"
        >
          {{ typeof newFindings === 'number' ? newFindings : '-' }}
        </div>
        <div
          class="text-xl text-center"
          :class="[newFindings > 0 ? 'text-black' : 'text-gray-500']"
        >
          new
        </div>
      </div>
 

      <!-- title, open findings, resolved findings; full height, right side of card -->
      <div class="ml-5 w-0 flex-1">
        <dl>
          <!-- card title -->
          <dt
            :class="[totalFindings > 0 ? 'text-gray-900' : 'text-gray-500']"
            class="text-2xl font-medium truncate"
          >
            {{ title }}
          </dt>
          
          <!-- open findings -->
          <dd>
            <div
              :class="[openFindings > 0 ? 'text-black' : 'text-gray-500']"
            >
              <span
                :class="{'w-5': (openFindings > 9 || resolvedFindings > 9 || totalFindings > 9)}"
                class="text-md text-right inline-block"
              >
                {{ typeof openFindings === 'number' ? openFindings : '-' }}
              </span>
              <span class="text-sm text-left  inline-block ml-2">
                open
              </span>
            </div>
          </dd>
          
          <!-- resolved findings -->
          <dd>
            <div class="text-gray-500">
              <span
                :class="{'w-5': (openFindings > 9 || resolvedFindings > 9 || totalFindings > 9)}"
                class="text-md text-right inline-block"
              >
                  {{ typeof resolvedFindings === 'number' ? resolvedFindings : '-' }}
              </span>
              <span class="text-sm text-left  inline-block ml-2"> resolved</span>
            </div>
          </dd>
        </dl>
      </div>

      <!-- show spinner animation if json hasn't been returned yet -->
      <div v-if="totalFindings===undefined">
        <font-awesome-icon
          :icon="['fas', 'spinner']"
          :class="jsonError===true ? 'text-red-400' : 'text-gray-400'"
          class="text-2xl animate-spin-pulse"
        />
      </div>
    </div>

    <!-- clickable area at bottom of card for total findings -->
    <router-link
      :to="page" 
      class="font-medium block px-5 py-3 rounded-b-lg bg-gray-50 hover:bg-gray-200 focus:outline-none focus:ring-inset focus:ring-2 focus:ring-gray-500"
    >
      <div class="">
        <div class="grid grid-cols-2 justify-items-stretch text-sm">
          <div class="text-left whitespace-nowrap">
            <span v-if="totalFindings !== undefined">
              {{ totalFindings }} total finding<span v-if="totalFindings !== 1">s</span>
            </span>
          </div>
          <div class="text-right">
            <span v-if="totalFindings !== undefined">
              View all
            </span>
            <span v-else> - </span>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    page:  String,
    icon:  String,
    newFindings:      Number,
    openFindings:     Number,
    resolvedFindings: Number,
    totalFindings:    Number,
    jsonError: Boolean,
  },
};
</script>