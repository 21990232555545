<template>
  <div class="hidden lg:flex lg:flex-shrink-0">
    <div
      class="flex flex-col w-56 border-r border-gray-200 pt-5 pb-4 text-white bg-gray-800"
    >
      <div class="mt-1 -mb-3">
        <img
          class="w-2/3 h-2/3 object-scale-down"
          src="/siftr-logo.png"
          alt="SIFTR logo"
        />      
      </div>

      <div class="h-0 flex-1 flex flex-col overflow-y-auto">
        <SidebarNav />
      </div>
    </div>
  </div>
</template>

<script>
import SidebarNav from "@/components/_common/SidebarNav.vue"

export default {
  components: {
    SidebarNav,
  },
}
</script>
