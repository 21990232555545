<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
  <!-- ------------------------------------------ -->
  <!-- Secrets content -->

    <!-- Secrets cards -->
    <SecretsOverview
      :num_findings="num_findings"
      :jsonError="this.$store.state.account.appErrorHeading !== null"
    />

    <!-- Secrets table -->
    <SecretsTable
      :findings="secrets"
      :secretType="secret_type"
      :jsonError="this.$store.state.account.appErrorHeading !== null"
    />

  <!-- end Secrets Content -->
  <!-- ------------------------------------------ -->
  </div>
</template>

<script>
import SecretsOverview from '@/components/pages/Secrets/SecretsOverview.vue';
import SecretsTable from '@/components/pages/Secrets/SecretsTable.vue';

// import json from '@/mock_data/response.json';
import { fetchMessage } from '@/services/fetchers';
import FormatDate from '@/services/DateFormat.js';
import userStrings from '@/strings/en.js';
import { EXTERNAL_API_URL_BASE } from '@/aws-config.js';

export default {
  components: {
    SecretsOverview,
    SecretsTable,
  },
  data() {
    return {
      // findings unique to Secrets
      secrets: null,
      secret_type: null,
      finding_types: userStrings.GITHUB.finding_type,
      num_findings: {
        other: null,
        ad_creds: null,
        api_key: null,
        third_party: null,
        database_creds: null,
      },
    }
  },
  async created() {    
    try {
      const json = await fetchMessage(`${EXTERNAL_API_URL_BASE}findings`);

      // set the local secrets value to an empty array
      this.secrets = [];

      // regardless of which page type we're on, reset all these now
      this.num_findings.other          = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
      this.num_findings.ad_creds       = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
      this.num_findings.api_key        = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
      this.num_findings.third_party    = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
      this.num_findings.database_creds = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};

      for (let finding of json){
        if (finding.finding_type === 'GITHUB'){
          finding.last_updated = FormatDate(finding.last_updated);
          this.secrets.push(finding);
          
          for (const repo_finding of finding.finding.repo_findings){
            this.num_findings[repo_finding.finding_type][finding.finding_status] += 1;
            this.num_findings[repo_finding.finding_type].TOTAL += 1;
          }
        }
      }
    }
    catch(error){
      this.$store.dispatch('account/setAppError', {
        appErrorHeading: 'There was an error retrieving your findings; try refreshing the page.  If the problem persists, let us know.',
        appErrorMessage: null
      });
    }
  },
};
</script>