<template>
  <div class="mt-8">
  
    <h2 class="text-2xl leading-6 font-medium text-gray-900">
      Overview
    </h2>

    <div class="mt-3 gap-5 xl:gap-7 grid grid-cols-1 md:grid-cols-2">
      <Card
        title="Secrets"
        page="secrets"
        v-if="findings.GITHUB"
        :newFindings     ="findings.GITHUB.NEW"
        :openFindings    ="findings.GITHUB.OPEN"
        :resolvedFindings="findings.GITHUB.RESOLVED"
        :totalFindings   ="findings.GITHUB.TOTAL"
        :jsonError       ="jsonError"
      />
      <Card
        title="Domain"
        page="domain"
        v-if="findings.DNSTWIST"
        :newFindings     ="findings.DNSTWIST.NEW"
        :openFindings    ="findings.DNSTWIST.OPEN"
        :resolvedFindings="findings.DNSTWIST.RESOLVED"
        :totalFindings   ="findings.DNSTWIST.TOTAL"
        :jsonError       ="jsonError"
      />
      <Card
        title="Data Breach"
        page="breach"
        v-if="findings.DEHASHED"
        :newFindings     ="findings.DEHASHED.NEW"
        :openFindings    ="findings.DEHASHED.OPEN"
        :resolvedFindings="findings.DEHASHED.RESOLVED"
        :totalFindings   ="findings.DEHASHED.TOTAL"
        :jsonError       ="jsonError"
      />
      <Card
        title="Perimeter"
        page="perimeter"
        v-if="findings.FOOTPRINT"
        :newFindings     ="findings.FOOTPRINT.NEW"
        :openFindings    ="findings.FOOTPRINT.OPEN"
        :resolvedFindings="findings.FOOTPRINT.RESOLVED"
        :totalFindings   ="findings.FOOTPRINT.TOTAL"
        :jsonError       ="jsonError"
      />
    </div>
  </div>
</template>

<script>
import Card from '@/components/pages/Dashboard/Card.vue';

export default {
  components: {
    Card,
  },
  props: {
    findings: Object,
    jsonError: Boolean,
  }
}
</script>