<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
  <!-- ------------------------------------------ -->
  <!-- SecretsType content -->

    <!-- Secrets table -->
    <SecretsTable
      :findings="secrets"
      :secretType="secret_type"
      :jsonError="errorHeading !== null"
    />

  <!-- end Secrets Content -->
  <!-- ------------------------------------------ -->
  </div>
</template>

<script>;
import SecretsOverview from '@/components/pages/Secrets/SecretsOverview.vue';
import SecretsTable from '@/components/pages/Secrets/SecretsTable.vue';

// import json from '@/mock_data/response.json';
import { fetchMessage } from '@/services/fetchers';
import FormatDate from '@/services/DateFormat.js';
import { EXTERNAL_API_URL_BASE } from '@/aws-config.js';

export default {
  components: {
    Error,
    SecretsOverview,
    SecretsTable,
  },
  data() {
    return {
      // findings unique to Secrets
      secrets: null,
      secret_type: null,
      
      finding_types: {
        other: 'Other',
        ad_creds: 'AD Credentials',
        api_key: 'API Keys',
        third_party: 'Third Party',
        database_creds: 'DB Credentials',
      },
    }
  },
  async created() {
    this.secret_type = this.$route.params.secret_type.replace('-', '_');

    try {
      const json = await fetchMessage(`${EXTERNAL_API_URL_BASE}findings`);
      // set the local secrets value to an empty array
      this.secrets = [];
      // create a temp variable because we'll lose scope for "this" inside the for-of loop
      const _secretType = this.secret_type;

      for (let finding of json){
        if (finding.finding_type === 'GITHUB'){
          for (const repo_finding of finding.finding.repo_findings){
            if (repo_finding.finding_type === _secretType){
              finding.last_updated = FormatDate(finding.last_updated);
              this.secrets.push(finding);
            }
          }
        }
      }
    }
    catch(error){
      this.$store.dispatch('account/setAppError', {
        appErrorHeading: 'There was an error retrieving your findings; try refreshing the page.  If the problem persists, let us know.',
        appErrorMessage: null
      });
    }
  },
};
</script>