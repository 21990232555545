<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
  <!-- ------------------------------------------ -->
  <!-- Domain content -->

    <!-- DNSTwist findings cards -->
    <DomainOverview
      :num_findings="num_findings"
      :jsonError="this.$store.state.account.appErrorHeading !== null"
    />

    <!-- DNSTwist findings table -->
    <DomainTable
      :findings="domain_findings"
      :jsonError="this.$store.state.account.appErrorHeading !== null"
    />
  <!-- end Domain Content -->
  <!-- ------------------------------------------ -->
  </div>
</template>

<script>
// unique to Domain
import DomainOverview from '@/components/pages/Domain/DomainOverview.vue';
import DomainTable from '@/components/pages/Domain/DomainTable.vue';

// import json from '@/mock_data/response.json';
import { fetchMessage } from '@/services/fetchers';
import FormatDate from '@/services/DateFormat.js';
import { EXTERNAL_API_URL_BASE } from '@/aws-config.js';

export default {
  components: {
    DomainOverview,
    DomainTable,
  },
  data() {
    return {
      // unique to Domain
      domain_findings: null,
      domain_category: null,
      num_findings: {
        parked: null,
        benign: null,
        cred_harvesting: null,
        malware: null,
        phishing: null,
      },
    }
  },
  async created() {
    try{
      const json = await fetchMessage(`${EXTERNAL_API_URL_BASE}findings`);

      this.domain_findings = [];
    
      // reset finding counts for overview cards
      this.num_findings.parked          = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
      this.num_findings.benign          = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
      this.num_findings.cred_harvesting = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
      this.num_findings.malware         = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
      this.num_findings.phishing        = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};
      this.num_findings.other           = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0};

      for (let finding of json){
        if (finding.finding_type === 'DNSTWIST'){
          finding.last_updated = FormatDate(finding.last_updated);
          // push finding to array for findings table
          this.domain_findings.push(finding);

          // increment findings counts for overview cards
          this.num_findings[finding.finding.domain_category][finding.finding_status] += 1;
          this.num_findings[finding.finding.domain_category].TOTAL += 1;
        }
      }
    }
    catch(error){
      this.$store.dispatch('account/setAppError', {
        appErrorHeading: 'There was an error retrieving your findings; try refreshing the page.  If the problem persists, let us know.',
        appErrorMessage: null
      });
    }
  }
};
</script>