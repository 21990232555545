<template>
<div>
  <div class="text-center">
    <h2 class="mt-6 text-2xl font-extrabold text-gray-800">
      Enable Two-Factor Authentication
    </h2>
    <p>Make your account safe in 3 easy steps:</p>
  </div>

  <div 
    class="bg-white shadow-sm overflow-hidden sm:rounded-md text-center mt-6"
  >
    <ul role="list" class="divide-y divide-gray-200">
      <li>
        <div class="flex px-4 py-4 sm:px-6 md:text">
          <div class="min-w-0 flex-1 flex">
          
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <h3 class="mt-6 text-xl font-bold text-gray-800">
                    DOWNLOAD AN AUTHENTICATOR APP
                </h3>
                <p>
                  Download and install 
                  <a 
                    target="_blank" 
                    href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" 
                    class="italic underline hover:text-gray-700"
                  >Google Authenticator</a>
                  or 
                  <a
                    target="_blank"
                    href="https://www.microsoft.com/en-us/p/microsoft-authenticator/9nblgggzmcj6" 
                    class="italic underline hover:text-gray-700"
                  >Microsoft Authenticator</a>
                  on your mobile device.
                </p>
              </div>
              <div>
                <font-awesome-icon
                  :icon="['fas','mobile-alt']"
                  class="text-9xl mx-auto mt-6"
                />
              </div>
            </div>
          </div>
        </div>
      </li>

      <li>
        <div class="flex px-4 py-4 sm:px-6 md:text">
          <div class="min-w-0 flex-1 flex">
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <h3 class="text-xl font-bold text-gray-800">
                  SCAN THE QR CODE
                </h3>
                <p>
                  Open the authenticator app and scan the image <span class="hidden md:inline">to the right</span><span class="md:hidden">below</span> using your mobile device's camera.
                </p>
              </div>

              <div class="">
                <vue-qrcode
                  :value="`otpauth://totp/SIFTR:${username}?secret=${code}&issuer=SIFTR`"
                  :options="{ width: 230, margin: 0 }"
                  class="mx-auto mt-6 md:mt-0"
                />
              </div>
            </div>
          </div>
        </div>
      </li>

      <li>
        <div class="flex items-center px-4 py-4 sm:px-6">
          <div class="min-w-0 flex-1 flex">
            <div class="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <h3 class="mt-6 text-xl font-bold text-gray-800">
                  ENTER YOUR CODE
                </h3>
                <p>
                  Enter the 6-digit verification code generated by your authenticator app.
                </p>
              </div>

              <div class="mt-5 sm:flex sm:items-center">
                <div class="w-full">
                  <input 
                    type="text"
                    name="totp"
                    id="totp"
                    v-focus
                    v-model="totp"
                    @keyup.enter="activate"
                    class="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:outline-none focus:ring-gray-500 focus:border-gray-500"
                    placeholder="000000"
                  />
                </div>
                <SubmitButton
                  text="Activate"
                  :action="this.activate"
                  :showLockIcon="false"
                  class="mt-3 px-4 py-2 sm:mt-0 sm:ml-3
                    w-full sm:w-auto
                    inline-flex items-center justify-center  border border-transparent shadow-sm font-medium rounded-md text-white 
                    sm:text-sm
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import { Auth } from 'aws-amplify';
import SubmitButton from '@/components/_common/SubmitButton.vue';
import aws_config   from '@/aws-config.js';

export default {
  name: 'MFASetup',
  data() {
    return {
      issuer: aws_config.Auth.userPoolId,
      username: this.$route.params.username,
      code: this.$route.params.code,
      totp: null,
    };
  },
  async mounted(){
    if (!this.username){
      this.$store.dispatch("account/logout");
      return false;
    }
  },
  components: {
    SubmitButton,
  },
  methods: {
    activate() {
      this.$store.dispatch('account/activateTOTP', {
        totp: totp.value,
      });  
    }
  }
};
</script>
