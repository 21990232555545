import store     from '@/store/store.js';
import { createWebHistory, createRouter } from 'vue-router';

import Main      from '@/components/pages/Main.vue';
// pages that don't require authenication to view
import Login     from '@/components/pages/Auth/Login.vue';
import NewPW     from '@/components/pages/Auth/NewPassword.vue';
import ForgotPW  from '@/components/pages/Auth/ForgotPassword.vue';
import ResetPW   from '@/components/pages/Auth/ResetPassword.vue';
import MFASetup  from '@/components/pages/Auth/MFASetup.vue';
import MFA       from '@/components/pages/Auth/MFA.vue';

// authenticated pages with static routes
import Dash      from '@/components/pages/Dashboard/Dashboard.vue';
import Secrets   from '@/components/pages/Secrets/Secrets.vue';
import Domain    from '@/components/pages/Domain/Domain.vue';
import Breach    from '@/components/pages/Breach/Breach.vue';
import Perimeter from '@/components/pages/Perimeter/Perimeter.vue';

// authenticated pages with dynamic routes (some part of the url is interpreted)
import Finding     from '@/components/pages/Finding/Finding.vue';
import SecretsType from '@/components/pages/Secrets/SecretsType.vue';
import DomainCat   from '@/components/pages/Domain/DomainCategory.vue';

// a route's "meta" field can contain any custom params you
// need.  we're using "auth" to designate whether the page 
// requires the user to be authenticated

const routes = [
  { 
    component: Main,      
    name: 'Main',    
    path: '/',
    meta: { auth: false, activeFeature: null },
   
    children:[
      // simple routes for public pages
      { component: Login,     name: 'Login',    path: '/',                meta: { auth: false, activeFeature: null } },
      { component: NewPW,     name: 'NewPW',    path: '/new-password',    meta: { auth: false, activeFeature: null } },
      { component: ForgotPW,  name: 'ForgotPW', path: '/forgot-password', meta: { auth: false, activeFeature: null } },
      { component: ResetPW,   name: 'ResetPW',  path: '/reset-password',  meta: { auth: false, activeFeature: null } },
      { component: MFASetup,  name: 'MFASetup', path: '/mfa-setup',       meta: { auth: false, activeFeature: null } },
      { component: MFA,       name: 'MFA',      path: '/mfa',             meta: { auth: false, activeFeature: null } },

      // simple routes for pages requiring auth to view
      { component: Dash,      name: 'Dashboard',path: '/dashboard',       meta: { auth: true, activeFeature: '/dashboard'} },
      { component: Secrets,   name: 'Secrets',  path: '/secrets',         meta: { auth: true, activeFeature: '/secrets'  } },
      { component: Domain,    name: 'Domain',   path: '/domain',          meta: { auth: true, activeFeature: '/domain'   } },
      { component: Breach,    name: 'Breach',   path: '/breach',          meta: { auth: true, activeFeature: '/breach'   } },
      { component: Perimeter, name: 'Perimeter',path: '/perimeter',       meta: { auth: true, activeFeature: '/perimeter'} },

      // dynamic routes
      { component: Finding,     name: 'Finding',        path: '/findings/:id',            meta: { auth: true, activeFeature: null } },
      { component: SecretsType, name: 'SecretsType',    path: '/secrets/:secret_type',    meta: { auth: true, activeFeature: '/secrets' } },
      { component: DomainCat,   name: 'DomainCategory', path: '/domain/:domain_category', meta: { auth: true, activeFeature: '/domain'  } },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  const route_requires_auth = to.meta.auth;

  // set activeFeature in vuex's 'ui' namespace on every route change
  store.dispatch('ui/updateActiveFeature', to.meta.activeFeature);
  // reset appErrors on every route change
  store.dispatch('account/resetAppError');

  // perform routing based on the status returned from fetchUser
  store.dispatch('account/fetchUser').then((status) => {
    if (status){
      if (route_requires_auth) {
        next();
      } else {
        // don't let authenticated users get back to
        // public pages; reroute to dashboard if needed
        next('/dashboard');
      }
    } else {
      if (route_requires_auth){
        // an unauthenticated user is trying to view a
        // page that requires auth; redirect to Login
        next('/');
      } else {
        // if the url doesn't match any valid route
        if (to.matched.length===0){
          // replace the path with '/' to redirect to
          // Login without causing infinite redirect
          router.replace({path: '/'})
        } else {
          next();
        }
      }
    }
  });
});

export default router;
