<template>
<div>
  <div class="text-center">
    <h2 class="mt-6 text-2xl font-extrabold text-gray-800">
      Enter the 2FA Code
    </h2>
    <p>
      Open your authenticator app and enter the code below.
    </p>
  </div>

  <div 
    class="bg-white shadow-sm overflow-hidden sm:rounded-md text-center mt-6"
  >      
    <div class="flex items-center px-4 py-4 sm:px-6">
      <div class="min-w-0 flex-1 flex">
        <div class="min-w-0 flex-1 px-4">
          <div class="my-3 sm:flex sm:items-center">
            <div class="w-full">
              <input 
                type="text"
                name="totp"
                id="totp"
                v-focus
                v-model="totp"
                @keyup.enter="login"
                class="shadow-sm focus:ring-gray-500 focus:border-gray-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="000000"
              />
            </div>
            <SubmitButton
              text="Login"
              :action="this.login"
              :showLockIcon="false"
              class="mt-3 sm:mt-0 sm:ml-3 w-full sm:w-auto"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ref } from 'vue';
import SubmitButton from '@/components/_common/SubmitButton.vue';

export default {
  name: 'MFA',
  setup(props) {
    const totp = ref(null);
    return { totp };
  },
  async mounted(){
    if (!this.$store.state.account.user){
      this.$store.dispatch("account/logout");
      return false;
    }
  },
  components: {
    SubmitButton,
  },
  methods: {
    login() {
      if (!this.totp || this.totp === '') {
        this.$store.dispatch('account/setAppError', {
          appErrorHeading: 'Error',
          appErrorMessage: '2FA code cannot be empty.'
        });

        return false;
      } else {
        this.$store.dispatch('account/resetAppError');
        this.$store.dispatch('account/mfa', {
          totp: totp.value,
          mfaType: this.$route.params.mfaType,
        });
      }
    }
  }
};
</script>
