<template>
  <div class="mt-6">
    <h2 class="text-2xl leading-6 font-medium text-gray-900">
      New Findings
    </h2>

    <div class="overflow-x-auto">
      <table class="table-auto min-w-full mt-3 border-2 border-collapse shadow rounded-lg">
        <thead>
          <tr
            class="
              border-t border-b border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              uppercase
              text-left text-sm
              tracking-wider
            "
          >
            <th class="px-4 md:px-5 py-3 font-medium">
              <!-- hide "Details" text for xs width, but keep the th element -->
              <span class="hidden sm:table-cell"> Details </span>
            </th>
            <!-- hide Category column for everything smaller than xl width -->
            <th
              @click="sort('feature', this.direction, this.findings)"
              class="hidden xl:table-cell whitespace-nowrap px-4 xl:px-6 py-3 font-medium cursor-pointer hover:bg-gray-200"
            >
              Category
              <font-awesome-icon 
                :icon="['fas', (this.direction.feature || 'sort')]"
                :class="this.direction.feature !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2"
              /> 
            </th>
            <th 
              @click="sort('source', this.direction, this.findings)" 
              class="px-4 xl:px-6 py-3 whitespace-nowrap font-medium cursor-pointer hover:bg-gray-200"
            >
              Source
              <font-awesome-icon
                :icon="['fas', (this.direction.source || 'sort')]"
                :class="this.direction.source !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2" 
              /> 
            </th>
            <th class="px-4 xl:px-6 py-3 font-medium"> Finding </th>
            <!-- hide Updated column for xs and sm width -->
            <th
              @click="sort('last_updated', this.direction, this.findings)"
              class="hidden md:table-cell whitespace-nowrap px-4 xl:px-6 py-3 font-medium cursor-pointer hover:bg-gray-200"
            >
              Updated
              <font-awesome-icon
                :icon="['fas', (this.direction.last_updated || 'sort')]"
                :class="this.direction.last_updated !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2" 
              /> 
            </th>
          </tr>
        </thead>

        <tbody
          v-if="this.findings !== null && this.findings.length > 0"
          class="bg-white divide-y divide-gray-100"
        >
          <tr v-for="(finding, index) in this.findings" :key="index">
            <!-- FA icon linking to full details -->
            <td class="text-gray-900 text-center hover:bg-gray-200">
              <router-link
                :to="'/findings/' + finding.id"
                class="block w-full h-full"
              >
                <font-awesome-icon :icon="['fas', 'arrow-circle-right']" />
              </router-link>
            </td>

            <!-- SIFTR feature ("Category") -->
            <td class="hidden xl:table-cell px-4 xl:px-6 py-3 text-gray-900">
              <span class="hidden sm:table-cell">
                {{ finding.feature }}
              </span>
            </td>

            <!-- finding source -->
            <td class="px-4 xl:px-6 py-3">
              <div class="flex items-center space-x-2">
                <div class="flex flex-shrink-0 space-x-1">
                  <span class="">
                    {{ finding.source }}
                  </span>
                </div>
              </div>
            </td>

            <!-- finding details -->
            <td class="px-4 xl:px-6 py-3">
              <div class="flex items-center space-x-2">
                <div class="flex flex-shrink-0 space-x-1 truncate">
                  <!-- GitHub -->
                  <span v-if="finding.finding_type === 'GITHUB'">
                    {{ finding.details.repo_name }}
                  </span>

                  <!-- Dehashed -->
                  <span
                    v-else-if="finding.finding_type === 'DEHASHED' || finding.finding_type === 'FOOTPRINT'"
                  >
                    <!-- TODO: Display number of findings found in this search -->
                  </span>

                  <!-- DNSTwist -->
                  <span v-else-if="finding.finding_type === 'DNSTWIST'">
                    <!-- user-readable domain category -->
                    {{ userStrings.DNSTWIST[finding.details.domain_category] }}
                  </span>
                </div>
              </div>
            </td>

            <!-- updated -->
            <td class="hidden md:table-cell px-4 xl:px-6 py-3">
              {{ finding.last_updated }}
            </td>
          </tr>
        </tbody>
        <tbody
          v-else-if="this.findings !== null && this.findings.length === 0"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            class="
              border-t border-b border-r border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              text-left text-sm
              tracking-wider
            "
          >
            <td colspan="5" class="px-4 xl:px-6 py-3">(no findings)</td>
          </tr>
        </tbody>
        <tbody
          v-else-if="this.findings === null"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            class="
              border-t border-b border-r border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              text-left text-sm
              tracking-wider
            "
          >
            <td colspan="5" class="px-4 xl:px-6 py-3">
              <font-awesome-icon
                :icon="['fas', 'spinner']"
                :class="jsonError === true ? 'text-red-400' : 'text-gray-400'"
                class="text-xl animate-spin-pulse"
              />
              <span class="ml-4 text-gray-400">loading...</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
</div>
</template>

<script>
import SortTable from '@/services/TableSort.js';
import userStrings from '@/strings/en.js';

export default {
  data() {
    return {
      direction: { feature: null, source: null, last_updated: 'sort-down'},
      userStrings: userStrings,
    };
  },
  props: {
    findings: Array,
    jsonError: Boolean,
  },
  methods: {
    sort: SortTable,
  },
   created(){
   this.sort('last_updated', this.direction, this.findings)
  }
};
</script>