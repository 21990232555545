<template>
<div class="min-h-screen flex items-center justify-center bg-gray-50 px-4 sm:px-6 lg:px-8">
  <div class="max-w-xl w-full space-y-8">
    <Error
      v-if="this.$store.state.account.appErrorHeading && this.$store.state.account.appErrorMessage"
      :heading="this.$store.state.account.appErrorHeading"
      :message="this.$store.state.account.appErrorMessage"
    />
    <div class="container mx-auto border-gray-200 pt-2 pb-2 text-white bg-gray-800">
      <img
        class="w-1/3 h-1/3 mx-auto  object-scale-down"
        src="/siftr-logo.png"
        alt="SIFTR logo"
      />
    </div>

    <router-view />
  </div>
</div>
</template>

<script>
import Error from '@/components/_common/Alerts/Error.vue';

export default {
  components: {
    Error
  }
}
</script>