<template>
  <AuthenticatedUI v-if="this.$store.state.account.authStatus === true" />
  <PublicUI v-else />
</template>

<script>
import AuthenticatedUI from '@/components/pages/AuthenticatedUI.vue';
import PublicUI        from '@/components/pages/PublicUI.vue';

export default {
  components:{
    AuthenticatedUI,
    PublicUI,
  },
};
</script>