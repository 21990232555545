import { Auth } from 'aws-amplify';
import Vuex     from 'vuex';
import router   from '@/router/routes.js';

const store = new Vuex.Store({
  modules: {
    ui: {
      namespaced: true,
      state: {
        // activeFeature can be a string like '/secrets', '/domain', '/breach', or '/perimeter'
        // we can set it when rendering a specific finding (Finding.vue)
        // and then use that shared state in SidebarItem to highlight 
        // the appropriate "parent" SidebarItem for this specific finding
        activeFeature: null,
      },
      mutations: {
        activeFeature(state, feature) {
          state.activeFeature = feature;
        },
      },
      actions: {
        updateActiveFeature({ commit }, feature) {
          commit('activeFeature', feature);
          return true;
        },
      }
    },

    account: {
      namespaced: true,

      // State ======================
      state: {
        user: null,
        loginStatus: null,
        appErrorHeading: null,
        appErrorMessage: null,
        authStatus: false,
      },

      // Mutations ==================
      mutations: {
        user(state, user) {
          state.user = user;
        },

        loginStatus(state, error) {
          state.loginStatus = error;
        },

        authStatus(state, status) {
          state.authStatus = status;
        },

        appError(state, error){
          state.appErrorHeading = error.appErrorHeading;
          state.appErrorMessage = error.appErrorMessage;
        }
      },

      // Actions ====================
      actions: {
        async login({ dispatch, commit, state }, { username, password }) {

          commit('loginStatus', null);
          await store.dispatch('account/resetAppError');

          try {
            const user = await Auth.signIn(username, password);

            // MFA is required for the user to complete login.
            if (user.challengeName === 'SMS_MFA' 
            ||  user.challengeName === 'SOFTWARE_TOKEN_MFA'){
              commit('loginStatus', user.challengeName);
              commit('user', user);
              router.push({ name: 'MFA', params: { mfaType: user.challengeName }});
            }
            // The user is logging in for the first time and needs to set a password.
            else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
              commit('loginStatus', 'NEW_PASSWORD_REQUIRED');
              commit('authStatus', false);
              commit('user', null);
              router.push({ name: 'NewPW' });
            }
            // The user needs to setup TOTP before using it.
            else if (user.challengeName === 'MFA_SETUP') {
              commit('loginStatus', 'MFA_SETUP');
              commit('user', user);
              Auth.setupTOTP(user).then((code) =>{
                router.push({ name: 'MFASetup', params: { code: code, username: username, password: password }});
              });
            
            // No MFA required; Login is complete. Handoff to fetchUser.
            } else {
              commit('user', user);
              await store.dispatch('account/fetchUser');
            }
            return true;

          // we end up in the cathch block if Auth.signIn() fails
          } catch (err) {
            if (err) {
              // FIXME: Are there other errors we should handle here?
              //        "User doesn't exist" is now handled in cognito.
              if (err.message === 'Incorrect username or password.') {
                commit('appError', { appErrorHeading: 'Authentication Error', appErrorMessage: err.message });
              } 
              // silently swallow other errors, I guess  -dan
            }
            return false;
          }
        },

        // fetchUser only handles assignment of user object
        // it is called before EVERY route change
        async fetchUser({ commit, state, dispatch }) {

          // ------------------------------------------------------
          // these loginStatus strings are special-cases for now;
          // Auth.currentSession() will return false in these cases
          // but we need to retain the state.user object from login()
          // so the user can attempt MFA.
          // ensure state.authStatus is false and return early.
          if (state.loginStatus === 'MFA_SETUP'
          ||  state.loginStatus === 'SMS_MFA'
          ||  state.loginStatus === 'SOFTWARE_TOKEN_MFA'
          ){
            commit('authStatus', false)
            return false;
          }
          // ------------------------------------------------------

          try {
            // Auth.currentSession() retrieves user auth data from LocalStorage
            // it checks Access token and ID token for validity first
            // if those are valid, the user stays signed in
            // if those aren't valid, then it checks the refresh token
            // if refresh token is still valid,  Auth.currentSession() will refresh the Access and ID tokens
            // if refresh token is no longer valid, Auth.currentSession() will fail and return false
            const session = await Auth.currentSession();
            commit('authStatus', true);

            // https://medium.com/@dantasfiles/three-methods-to-get-user-information-in-aws-amplify-authentication-e4e39e658c33
            const user = await Auth.currentAuthenticatedUser();
            // TODO: maybe refactor to only store username?
            commit('user', user);

            const token = user.getSignInUserSession().getIdToken().getJwtToken();
            localStorage.setItem('JWT', token);

            return true;

          } catch (err) {
            // Auth.signOut() should be called when local storage tokens expire 
            // or no session can be found; call it to revoke Cognito token
            await Auth.signOut();
            commit('user', null);
            // remove the JWT from Local Storage
            localStorage.removeItem('JWT');
            commit('authStatus', false)

            return false;
          }
        },

        async logout({ commit }) {
          // call Auth.signOut() to revoke Cognito token  
          await Auth.signOut();
          
          commit('authStatus', false);

          // remove the JWT from Local Storage
          localStorage.removeItem('JWT');
         
          commit('user', null);
          commit('loginStatus', null);

          //send user back to Login
          router.push({ name: 'Login' });

          return true;
        },

        // activateTOTP() should be used once, when a user is
        // setting up their MFA authenticator app for the first time
        // call it like:
        //   store.dispatch('account/activateTOTP', { totp: userTotpValue });
        async activateTOTP({ state }, params){
          try {
            await Auth.verifyTotpToken(state.user, params.totp)
            await Auth.setPreferredMFA(state.user, 'TOTP');
            router.push({ name: 'Dashboard' });

          } catch (err) {
            // Token is not verified
            // console.log(err);
          };
        },

        // mfa() should be used for MFA logins
        // for SMS,  this is every login
        // for TOTP, this is every login after the initial setup (see: activateTOTP)
        // call it like:
        //   store.dispatch('account/mfa', { totp: userTotpValue, mfaType: userMfaType });
        async mfa({ state, commit }, params){
          try {
            await Auth.confirmSignIn( 
                state.user,    // object returned from Auth.signIn()
                params.totp,   // Confirmation code  
                params.mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
            );
            
            // MFA succeeded; remove any special loginStatus values now.
            commit('loginStatus', null);
            router.push({ name: 'Login' });
            return true;

          } catch (err) {
            await store.dispatch('account/setAppError', { appErrorHeading: 'Error', appErrorMessage: 'Invalid code.' });
            return false;
          }
        },

        // call store.dispatch('account/setAppError') to set 
        // the error heading and message used by the Error component
        async setAppError({commit}, error){
          commit('appError', { appErrorHeading: error.appErrorHeading, appErrorMessage: error.appErrorMessage });
        },

        // call store.dispatch('account/resetAppError') to reset 
        // the error heading and message back to null
        // effectively hiding the error alert component
        async resetAppError({commit}){
          commit('appError', { appErrorHeading: null, appErrorMessage: null });
        },
      }
    }
  }
});

// Export =====================
export default store;