<template>
<div>
  <div class="py-4 px-6 grid grid-cols-3 gap-4">
    <dt class="text-md font-medium text-gray-500">
      File Download
    </dt>

    <dd class="text-md text-gray-900 sm:col-span-2">
      <span
        @click="downloadFinding(finding.finding_id, 'csv')"
        class="text-blue-600 hover:underline cursor-pointer w-auto h-auto"
      >
        <font-awesome-icon :icon="['fas', 'file-download']" />
        <span class="pl-1">
          CSV File
        </span>
      </span>
    </dd>
  </div>
</div>
</template>
   
<script>
import { downloadFinding } from '@/services/fetchers';

export default {
  props: {
    finding: Object,
  },
  methods:{
    downloadFinding: downloadFinding,
  },
};
</script>