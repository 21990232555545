<template>
<div>
  <!-- GitHub Repository name -->
  <div class="py-4 px-6 grid grid-cols-3 gap-4">
    <dt class="text-md font-medium text-gray-500">
      Repository
    </dt>
    <dd class="text-md text-gray-900 sm:col-span-2">
      github.com/{{ finding.finding.repo_name }}
    </dd>
  </div>


  <!-- GitHub finding list -->
  <div class="divide-y divide-gray-200">
    <!-- GitHub: loop for repo_findings -->
    <div
      v-for="(f, index) in finding.finding.repo_findings"
      :key="index"
      class="py-4 px-6 sm:grid sm:grid-cols-3 gap-4"
    >
      <!-- github loop: finding number -->
      <p class="text-xl text-gray-600 pt-0 sm:pt-2 sm:col-span-1">
        Finding {{ index + 1 }}
      </p>

      <!-- github loop: finding_type -->
      <div class="pt-4 sm:pt-0">
        <p class="text-2xl sm:pt-2 text-gray-900 sm:col-span-2">
          {{ this.userStrings[f.finding_type] }}
        </p>
      </div>

      <!-- github loop: link to file  -->
      <div class="pt-4 sm:pt-0 sm:col-span-2">
        <dt class="text-md font-medium text-gray-500">
          File
        </dt>
        <dd class="text-md text-gray-900 sm:col-span-2">
          <a
            target="_blank"
            :href="f.link_to_file"
            class="text-indigo-600 hover:text-indigo-900 whitespace-nowrap"
          >
            <font-awesome-icon
              :icon="['fas', 'external-link-alt']"
              class="mr-2"
            />
            {{ f.link_to_file.split("/").pop() }}
          </a>
        </dd>
      </div>

      <!-- github loop: finding description  -->
      <div class="pt-4 sm:pt-0 sm:col-span-2">
        <dt class="text-md font-medium text-gray-500">
          Description
        </dt>
        <dd class="text-md text-gray-900 sm:col-span-2">
          {{ f.description }}
        </dd>
      </div>
    </div><!-- end GitHub loop -->
  </div>
</div>
</template>
   
<script>
import userStrings from '@/strings/en.js';

export default {
  data(){
    return {
      userStrings: userStrings.GITHUB.finding_type
    }
  },
  props: {
    finding: Object,
  },
};
</script>