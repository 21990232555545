import _ from 'lodash';

// ----------------------------------------------------------
// OppositeSort is a convenience object for string lookup
// 'sort-up' and 'sort-down' are used because they are FontAwesome icons
const OppositeSort = {
  ['sort-up']: 'sort-down',
  ['sort-down']: 'sort-up',
}

// ----------------------------------------------------------
const SortTable = function(colName, colDirections, findings) {
  if (findings === null || typeof findings !== 'object') {
    return false;
  }
  
  // curSort is a string value representing the desired column's current sort order
  // if this column hasn't been sorted yet, colDirections[colName] will be null
  // use a default value of 'sort-down' if null
  const curSort = colDirections[colName] ?? 'sort-down';
  // newSort is a string value for what the next sort should be
  const newSort = OppositeSort[curSort];

  // ----------------------------------------------------------
  // perform the sort

  // the 'last_updated' column is a special case for now
  // sort it by converting human-readable strings to Date objects
  if (colName === 'last_updated') {
    
    findings.sort((a, b) => {
      if (curSort === 'sort-up') {
        // sort from oldest (top) to most recent (bottom)
        return (new Date(a.last_updated) - new Date(b.last_updated));

      } else {
        // sort from most recent (top) to oldest (bottom)
        return (new Date(b.last_updated) - new Date(a.last_updated));
      }
    });
 
  //all other columns; sort generic string data
  } else {
    findings.sort((a, b) => {
      if (_.get(a,colName).toLowerCase() < _.get(b,colName).toLowerCase()) {
        return curSort === 'sort-up' ? -1 : 1;

      } else if (_.get(a,colName).toLowerCase() > _.get(b,colName).toLowerCase()) {
        return curSort === 'sort-up' ? 1 : -1;

      } else {
        return 0;
      }
    });
  }

  // reset all column sort flags to null
  Object.keys(colDirections).forEach((key) => { colDirections[key] = null; });
  // then set the desired column's sort flag appropriately
  colDirections[colName] = newSort;
}

export default SortTable;