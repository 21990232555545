<template>
<div>
  <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-800">
   Enter your username
  </h2>
  <p class="mt-6 text-center text-xl font-bold text-gray-800">
    We will send a confirmation code to the email address registered to your account.
  </p>
</div>
<div class="mt-8 space-y-6">
  <input type="hidden" name="remember" value="true" />
  <div class="rounded-md shadow-sm -space-y-px">
    <div>
      <label for="username" class="sr-only">Username</label>
      <input
        v-model="username"
        id="username"
        name="username"
        type="text"
        v-focus
        placeholder="Username"
        @keyup.enter="submit"
        required
        class="
            appearance-none
            rounded-md
            block
            w-full px-3 py-2
            border border-gray-300
            placeholder-gray-500 text-gray-900
            focus:outline-none focus:ring-inset focus:ring-gray-500 focus:border-gray-500
            sm:text-sm
        "
      />
    </div>
  </div>

  <SubmitButton text="Submit" :action="this.submit" />
</div>
</template>

<script>
import {Auth} from 'aws-amplify';
import SubmitButton from '@/components/_common/SubmitButton.vue';

export default {
  name: 'ForgotPassword',
  components: {
    SubmitButton,
  },
  data() {
    return {
      username: '',
    };
  },
  methods: {
    submit() {
      if (!this.username || this.username === ''){
        this.$store.dispatch('account/setAppError', {
          appErrorHeading: 'Error',
          appErrorMessage: 'Username cannot be empty.'
        });
      } else {
        // clear app error
        this.$store.dispatch('account/resetAppError');
        
        Auth.forgotPassword(this.username).then(data => {
          this.$router.push( { name: 'ResetPW' } );
        });
      }
    },
  },
};
</script>
