const userStrings = {
  GITHUB:{
    feature: 'Secrets',
    source:  'GitHub',
    page:    'secrets',
    finding_type: {
      other:       'Other',
      ad_creds:    'AD Credentials',
      api_key:     'API Key',
      third_party: 'Third Party',
      database_creds: 'DB Credentials'
    }
  },
  DEHASHED: {
    feature: 'Data Breach',
    source:  'DeHashed',
    page:    'breach',
  },
  DNSTWIST: {
   feature: 'Domain',
    source:   'DNSTwist',
    page:     'domain',
    domain_category: {
      other: 'Other',
      parked: 'Parked',
      benign: 'Benign',
      cred_harvesting: 'Credential  Harvesting',
      malware: 'Malware',
      phishing: 'Phishing',
    }
  },
  FOOTPRINT: {
    feature: 'Perimeter',
    source:  'SRA Footprint',
    page:    'perimeter',
  }
};

export default userStrings;