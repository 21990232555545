<template>
  <router-link
    v-if="page"
    :to="page"
    @click="this.$emit('menuToggled')"
    class="text-gray-300 hover:bg-gray-700 group items-center px-2 py-2 font-medium rounded-md grid grid-cols-6 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-inset"
    :class="this.$store.state.ui.activeFeature===page ? 'bg-gray-700' : ''"
  >

    <span v-if="faIcon !== ''">
      <font-awesome-icon class="ml-1" :icon="['fas', faIcon]" />
    </span>
    
    <!-- local SVG file otherwise -->
    <span v-else-if="faIcon === ''">
      <!--  FIXME: this code is kind of ugly 
            remove whitespace so that "Data Breach" → "DataBreach"
      -->
      <img
        :src="`/Icons/${title.replace(/ /g,'')}.svg`"
        class="ml-1 w-5"
        :alt="title + 'SVG image'"
      />
    </span>

    <span class="col-span-5">
      {{ title }}
    </span>
  </router-link>

  <a
    v-else-if="url"
    :href="url"
    target="_blank"
    @click="this.$emit('menuToggled')"
    class="text-gray-300 hover:bg-gray-700 group items-center px-2 py-2 font-medium rounded-md grid grid-cols-6 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-inset"
  >
    <span v-if="faIcon !== ''">
      <font-awesome-icon class="ml-1" :icon="['fas', faIcon]" />
    </span>

    <!-- local SVG file otherwise -->
    <span v-else-if="faIcon === ''">
      <img
        :src="`/Icons/${title.replace(/ /g,'')}.svg`"
        class="ml-1 w-5"
        :alt="title + 'SVG image'"
      />
    </span>

    <span class="col-span-5">
      {{ title }}
    </span>
  </a>

</template>

<script>
export default {
  props: {
    // use 'url' for external domains
    url:    String,
    // use 'page' to link within siftr
    page:   String,
    title:  String,
    faIcon: String,   
  },
  emits: ["menuToggled"],
}
</script>