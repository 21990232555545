<template>
  <div
    class="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200"
  >
    <MobileMenuToggle @menuToggled="this.$emit('menuToggled')" />
    <!-- Top bar - Search, User Profile -->
    <div
      class="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8"
    >
      <!-- Search, absent for now -->
      <div class="flex-1 flex"></div>

      <div class="ml-4 flex items-center md:ml-6">
        <!-- Profile dropdown -->
        <UserMenu />
      </div>
    </div>
  </div>
</template>

<script>
import MobileMenuToggle from "@/components/_common/MobileMenuToggle.vue"
import UserMenu from "@/components/_common/UserMenu.vue"

export default {
  components: {
    MobileMenuToggle,
    UserMenu,
  },
  emits:['menuToggled']
}
</script>