<template>
<button
  type="button"
  @click="action"
  class="
    w-full flex justify-center
    py-2 px-4
    border border-transparent 
    text-sm font-medium 
    text-white 
    bg-gray-900 hover:bg-gray-700 
    focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 
    rounded-md cursor-pointer
  " 
>
  <!-- Heroicon name: solid/lock-closed -->
  <svg
    v-if="showLockIcon"
    class=" h-5 w-5 flex justify-left text-gray-500 group-hover:text-gray-400"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    aria-hidden="true"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
    />
  </svg>
  <span>
    {{ text }}
  </span>
</button>
</template>

<script>
export default {
  name: "Button",
  props: {
    text: String,
    action: Function,
    showLockIcon: {
      type: Boolean,
      default: true
    }
  },
};
</script>