<template>
  <div>

  <h2 class="text-2xl leading-6 font-medium text-gray-900">
    Secrets Overview
  </h2>

  <div class="mt-3 gap-5 xl:gap-7 grid grid-cols-1 md:grid-cols-2">

    <Card
      v-if="this.num_findings.ad_creds && this.num_findings.ad_creds.TOTAL > 0"
      title="AD Credentials"
      page="/secrets/ad-creds"
      :newFindings     ="num_findings.ad_creds.NEW"
      :openFindings    ="num_findings.ad_creds.OPEN"
      :resolvedFindings="num_findings.ad_creds.RESOLVED"
      :totalFindings   ="num_findings.ad_creds.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="this.num_findings.database_creds && this.num_findings.database_creds.TOTAL > 0"
      title="Database Credentials"
      page="/secrets/database-creds"
      :newFindings     ="num_findings.database_creds.NEW"
      :openFindings    ="num_findings.database_creds.OPEN"
      :resolvedFindings="num_findings.database_creds.RESOLVED"
      :totalFindings   ="num_findings.database_creds.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="this.num_findings.api_key && this.num_findings.api_key.TOTAL > 0"
      title="API Keys"
      page="/secrets/api-key"
      :newFindings     ="num_findings.api_key.NEW"
      :openFindings    ="num_findings.api_key.OPEN"
      :resolvedFindings="num_findings.api_key.RESOLVED"
      :totalFindings   ="num_findings.api_key.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="this.num_findings.third_party && this.num_findings.third_party.TOTAL > 0"
      title="Third Party"
      page="/secrets/third-party"
      :newFindings     ="num_findings.third_party.NEW"
      :openFindings    ="num_findings.third_party.OPEN"
      :resolvedFindings="num_findings.third_party.RESOLVED"
      :totalFindings   ="num_findings.third_party.TOTAL"
      :jsonError       ="jsonError"
    />

    <Card
      v-if="this.num_findings.other && this.num_findings.other.TOTAL > 0"
      title="Other"
      page="/secrets/other"
      :newFindings     ="num_findings.other.NEW"
      :openFindings    ="num_findings.other.OPEN"
      :resolvedFindings="num_findings.other.RESOLVED"
      :totalFindings   ="num_findings.other.TOTAL"
      :jsonError       ="jsonError"
    />
  </div>
</div>
</template>

<script>
import Card from '@/components/pages/Dashboard/Card.vue';

export default {
  components: {
    Card,
  },
  props: {
    num_findings: Object,
    jsonError: Boolean,
  },
};
</script>