<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
    <!-- ------------------------------------------ -->
    <!-- Perimeter content -->

    <!-- all Footprinting findings table -->
    <PerimeterTable :findings="perimeter_findings" :jsonError="this.$store.state.account.appErrorHeading !== null" />

    <!-- end Perimeter Content -->
    <!-- ------------------------------------------ -->
  </div>
</template>

<script>
// unique to Perimeter
import PerimeterTable from '@/components/pages/Perimeter/PerimeterTable.vue'

// mock data
// import json from '@/mock_data/response.json';
import { fetchMessage } from '@/services/fetchers';
import FormatDate from '@/services/DateFormat.js';
import { EXTERNAL_API_URL_BASE } from '@/aws-config.js';

export default {
  components: {
    PerimeterTable,
  },
  data() {
    return {
      // findings unique to Perimeter
      perimeter_findings: null,
    }
  },
  async created() {
    try {
      const json = await fetchMessage(`${EXTERNAL_API_URL_BASE}findings`);
      this.perimeter_findings = [];
      
      // TODO: make functional rather than procedural 
      for (let finding of json){
        if (finding.finding_type === 'FOOTPRINT'){
          finding.last_updated = FormatDate(finding.last_updated);
          this.perimeter_findings.push(finding);
        }
      }
    }
    catch(error){
      this.$store.dispatch('account/setAppError', {
        appErrorHeading: 'There was an error retrieving your findings; try refreshing the page.  If the problem persists, let us know.',
        appErrorMessage: null
      });
    }
  }
};
</script>