export default {
  Auth: { 
    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',
    
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'us-east-1_lEo3ZZsRi',
    
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '1jp3rqmo1kf4va5qbkrrq7j4ai',
    
    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_SRP_AUTH',
    
    // OPTIONAL - Hosted UI configuration
    oauth: {
      domain: "siftrprod-secrets-monitoring.auth.us-east-1.amazoncognito.com",
      scope: [
        "aws.cognito.signin.user.admin",
        "email",
        "openid",
        "phone",
        "profile"
      ],
      "redirectSignIn": "https://127.0.0.1:8080",
      "redirectSignOut": "https://127.0.0.1:8080",
      "responseType": "code"
    }
  }
}

// You must fill this value in manually after the External API has been created
export const EXTERNAL_API_URL_BASE = "https://xsbd7j9yua.execute-api.us-east-1.amazonaws.com/api/";
