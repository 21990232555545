<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
    <!-- ------------------------------------------ -->
    <!-- Breach content -->

    <!-- all Dehashed findings table -->
    <BreachTable :findings="breach_findings" :jsonError="this.$store.state.account.appErrorHeading !== null" />

    <!-- end Breach Content -->
    <!-- ------------------------------------------ -->
  </div>
</template>

<script>
// unique to Breach
import BreachTable from '@/components/pages/Breach/BreachTable.vue';

// import json from '@/mock_data/response.json';
import FormatDate from '@/services/DateFormat.js';
import { fetchMessage } from '@/services/fetchers';
import { EXTERNAL_API_URL_BASE } from '@/aws-config.js';

export default {
  components: {
    BreachTable,
  },
  data() {
    return {
      // findings unique to Breach
      breach_findings: null,
    }
  },
  async created() {
    try {
      const json = await fetchMessage(`${EXTERNAL_API_URL_BASE}findings`);
      this.breach_findings = [];

      for (let finding of json){
        if (finding.finding_type === 'DEHASHED'){
          finding.last_updated = FormatDate(finding.last_updated);
          this.breach_findings.push(finding);
        }
      }
    }
    catch(error){
      this.$store.dispatch('account/setAppError', {
        appErrorHeading: 'There was an error retrieving your findings; try refreshing the page.  If the problem persists, let us know.',
        appErrorMessage: null
      });
    }
  }
};
</script>