<template>
<div>
  <!-- DNSTwist: domain name -->
  <div class="py-4 px-6 grid grid-cols-3 gap-4">
    <dt class="text-md font-medium text-gray-500">Domain Name</dt>
    <dd class="text-md text-gray-900 sm:col-span-2">
      {{ finding.finding.domain_name }}
    </dd>
  </div>

  <!-- DNSTwist: domain registration -->
  <div class="py-4 px-6 grid grid-cols-3 gap-4">
    <dt class="text-md font-medium text-gray-500">
      Domain Registration
    </dt>
    <dd class="text-md text-gray-900 sm:col-span-2">
      {{ this.formatDate(finding.finding.domain_registration_date) }}
    </dd>
  </div>

  <!-- DNSTwist: domain IPv4 -->
  <div class="py-4 px-6 grid grid-cols-3 gap-4">
    <dt class="text-md font-medium text-gray-500">
      Domain A Record
    </dt>
    <dd class="text-md text-gray-900 sm:col-span-2">
      <ul>
        <li
          v-for="(address, index) in finding.finding.domain_a_record"
          :key="index"
        >
          {{ address }}
        </li>
      </ul>
    </dd>
  </div>

  <!-- DNSTwist: domain ns record -->
  <div class="py-4 px-6 grid grid-cols-3 gap-4">
    <dt class="text-md font-medium text-gray-500">
      Domain NS Record
    </dt>
    <dd class="text-md text-gray-900 sm:col-span-2">
      <ul>
        <li
          v-for="(record, index) in finding.finding.domain_ns_record"
          :key="index"
        >
          {{ record }}
        </li>
      </ul>
    </dd>
  </div>

  <!-- DNSTwist: domain cname record -->
  <div class="py-4 px-6 grid grid-cols-3 gap-4">
    <dt class="text-md font-medium text-gray-500">
      Domain CNAME Record
    </dt>
    <dd class="text-md text-gray-900 sm:col-span-2">
      <ul>
        <li
          v-for="(record, index) in finding.finding.domain_cname_record"
          :key="index"
        >
          {{ record }}
        </li>
      </ul>
    </dd>
  </div>

  <!-- DNSTwist: domain mx record -->
  <div class="py-4 px-6 grid grid-cols-3 gap-4">
    <dt class="text-md font-medium text-gray-500">
      Domain MX Record
    </dt>
    <dd class="text-md text-gray-900 sm:col-span-2">
      <ul>
        <li
          v-for="(record, index) in finding.finding.domain_mx_record"
          :key="index"
        >
          {{ record }}
        </li>
      </ul>
    </dd>
  </div>

  <!-- DNSTwist: domain category -->
  <div class="py-4 px-6 grid grid-cols-3 gap-4">
    <dt class="text-md font-medium text-gray-500">
      Domain Category
    </dt>
    <dd class="text-md text-gray-900 sm:col-span-2">
      {{ finding.finding.domain_category }}
    </dd>
  </div>
</div>
</template>
   
<script>
export default {
  props: {
    finding: Object,
    formatDate: Function,
  },
};
</script>