<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
  <!-- ------------------------------------------ -->
  <!-- DomainCateogry content -->

    <!-- DNSTwist findings table -->
    <DomainTable 
      :findings="domain_findings"
      :domainCategory="domain_category"
      :jsonError="this.$store.state.account.appErrorHeading !== null"
    />
      
  <!-- end Domain Content -->
  <!-- ------------------------------------------ -->
  </div>
</template>

<script>
// unique to Domain
import DomainOverview from '@/components/pages/Domain/DomainOverview.vue';
import DomainTable from '@/components/pages/Domain/DomainTable.vue';

// import json from '@/mock_data/response.json';
import { fetchMessage } from '@/services/fetchers';
import FormatDate from '@/services/DateFormat.js';
import { EXTERNAL_API_URL_BASE } from '@/aws-config.js';

export default {
  components: {
    DomainOverview,
    DomainTable,
  },
  data() {
    return {
      // unique to Domain
      domain_findings: null,
      domain_category: null,
    }
  },
  async created() {
    this.domain_category = this.$route.params.domain_category.replace('-', '_');

    try {
      const json = await fetchMessage(`${EXTERNAL_API_URL_BASE}findings`);
      this.domain_findings = [];
      const _domainCategory = this.domain_category;
    
      for (let finding of json){
        if (finding.finding_type === 'DNSTWIST' && finding.finding.domain_category === _domainCategory){
          finding.last_updated = FormatDate(finding.last_updated);
          // push finding to array for findings table
          this.domain_findings.push(finding);
        }
      }
    }
    catch(error){
      this.$store.dispatch('account/setAppError', {
        appErrorHeading: 'There was an error retrieving your findings; try refreshing the page.  If the problem persists, let us know.',
        appErrorMessage: null
      });
    }
  },
};
</script>