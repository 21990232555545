<template>
  <nav class="px-3 mt-3 divide-y divide-black">
    <div class="space-y-1 pb-6">
      <SidebarItem title="Dashboard"   page="/dashboard" faIcon="home"  @menuToggled="this.$emit('menuToggled')" />
      <SidebarItem title="Secrets"     page="/secrets"   faIcon=""      @menuToggled="this.$emit('menuToggled')" />
      <!-- hide modules -->
      <!-- <SidebarItem title="Domain"      page="/domain"    faIcon=""      @menuToggled="this.$emit('menuToggled')" />-->
      <SidebarItem title="Data Breach" page="/breach"    faIcon=""      @menuToggled="this.$emit('menuToggled')" />
      <!-- <SidebarItem title="Perimeter"   page="/perimeter" faIcon=""      @menuToggled="this.$emit('menuToggled')" />-->
    </div>
    <div class="space-y-1 pt-6">
      <!-- FIXME: Help page doesn't exist yet. -->
      <SidebarItem title="Help"  url="https://docs.siftr.sra.io/" faIcon="question"  @menuToggled="this.$emit('menuToggled')" />
      <SidebarItem title="About" url="https://siftr.sra.io/"      faIcon="info"      @menuToggled="this.$emit('menuToggled')" />
    </div>
  </nav>
</template>

<script>
import SidebarItem from "@/components/_common/SidebarItem.vue"

export default {
  components: {
    SidebarItem,
  },
  emits: ["menuToggled"],
};
</script>