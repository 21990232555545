<template>
  <div class="mt-8">
    
    <h2 class="text-2xl leading-6 font-medium text-gray-900">
      Domain - 
      <span v-if="domainCategory == null || domain_categories[domainCategory] == null">
        All Findings
      </span>
      <span v-else>
        {{ domain_categories[domainCategory] }}
      </span>
    </h2>

    <div class="overflow-x-auto">
      <table class="table-auto min-w-full mt-3 border-2 border-collapse shadow rounded-lg">
        <thead>
          <tr
            class="
              border-t border-gray-200 border-b border-l-8
              text-gray-700
              bg-gray-50
              uppercase
              text-left text-sm
              tracking-wider
            "
          >
            <th class="px-4 md:px-5 py-3 font-medium">
              <!-- hide "Details" text for xs width, but keep the th element -->
              <span class="hidden sm:table-cell"> Details </span>
            </th>
            <th
              @click="sort('finding.domain_name', this.direction, this.findings)"
              class="px-4 md:px-5 xl:px-6 py-3 whitespace-nowrap font-medium cursor-pointer hover:bg-gray-200"
            >
              Name
              <span class="xl:hidden"> + IPv4</span>
              <font-awesome-icon 
                :icon="['fas', (this.direction['finding.domain_name'] || 'sort')]"
                :class="this.direction['finding.domain_name'] !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2"
              /> 
            </th>

            <th class="hidden xl:table-cell md:px-5 xl:px-6 py-3 font-medium">
              IPv4
            </th>

            <th
              class="px-4 md:px-5 xl:px-6 py-3 font-medium cursor-pointer hover:bg-gray-200"
            >
              NS Record
            </th>

            <th
              @click="sort('finding_status', this.direction, this.findings)"
              class="px-4 md:px-5 xl:px-6 py-3 whitespace-nowrap font-medium cursor-pointer hover:bg-gray-200"
            >
              Status
              <font-awesome-icon 
                :icon="['fas', (this.direction.finding_status || 'sort')]"
                :class="this.direction.finding_status !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2"
              /> 
            </th>

            <th
              @click="sort('last_updated', this.direction, this.findings)"
              class="hidden md:table-cell md:px-5 xl:px-6 py-3 whitespace-nowrap font-medium cursor-pointer hover:bg-gray-200"
            >
              Updated
              <font-awesome-icon 
                :icon="['fas', (this.direction.last_updated || 'sort')]"
                :class="this.direction.last_updated !== null ? 'text-gray-700' : 'text-gray-400'"
                class="ml-2"
              />
            </th>
          </tr>
        </thead>

        <tbody
          v-if="this.findings !== null && this.findings.length > 0"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            v-for="finding in this.findings"
            :key="finding.id"
            class="border-t border-gray-300"
            :class="this.colors.tr[finding.finding_status]"
          >
            <!-- link to full findings -->
            <td
              class="border-l-8 text-gray-900"
              :class="this.colors.td[finding.finding_status]"
            >
              <router-link
                :to="'/findings/' + finding.finding_id"
                class="pl-10 pr-6 py-4 block w-auto h-auto"
              >
                <font-awesome-icon :icon="['fas', 'arrow-circle-right']" />
              </router-link>
            </td>

            <!-- name -->
            <td class="px-4 md:px-5 xl:px-6 py-3">
              <div class="items-center space-x-2">
                <div>
                  {{ finding.finding.domain_name }}

                  <ul class="xl:hidden">
                    <li
                      v-for="(address, index) in finding.finding.domain_a_record"
                      :key="index"
                    >
                      {{ address }}
                    </li>
                  </ul>
                </div>
              </div>
            </td>

            <!-- IPv4 -->
            <td class="hidden xl:table-cell xl:px-6 py-3">
              <div class="flex items-center space-x-2">
                <div class="flex flex-shrink-0 whitespace-nowrap space-x-1">
                  <ul>
                    <li
                      v-for="(address, index) in finding.finding.domain_a_record"
                      :key="index"
                    >
                      {{ address }}
                    </li>
                  </ul>
                </div>
              </div>
            </td>

            <!-- NS Record -->
            <td class="px-4 md:px-5 xl:px-6 py-3">
              <div class="flex items-center space-x-2">
                <div class="flex flex-shrink-0 whitespace-nowrap space-x-1">
                  <ul>
                    <li
                      v-for="(record, index) in finding.finding.domain_ns_record"
                      :key="index"
                    >
                        {{ record }}
                      </li>
                    </ul>
                  </div>
                </div>
              </td>

              <!-- status -->
              <td class="px-4 md:px-5 xl:px-6 py-3">
                <div class="flex items-center space-x-2">
                  <div
                    class="flex flex-shrink-0 space-x-1 font-semibold"
                    :class="this.colors.text[finding.finding_status]"
                  >
                    {{ this.statuses[finding.finding_status] }}
                  </div>
                </div>
              </td>

              <td class="hidden md:table-cell md:px-5 xl:px-6 py-3">
                {{ finding.last_updated }}
              </td>
            </tr>
        </tbody>

        <tbody
          v-else-if="this.findings !== null && this.findings.length === 0"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            class="
              border-t border-b border-r border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              text-left text-sm
              tracking-wider
            "
          >
            <td colspan="6" class="px-4 xl:px-6 py-3">(no findings)</td>
          </tr>
        </tbody>

        <tbody
          v-else-if="this.findings === null"
          class="bg-white divide-y divide-gray-100"
        >
          <tr
            class="
              border-t border-b border-r border-l-8 border-gray-200
              text-gray-700
              bg-gray-50
              text-left text-sm
              tracking-wider
            "
          >
            <td colspan="6" class="px-4 xl:px-6 py-3">
              <font-awesome-icon
                :icon="['fas', 'spinner']"
                :class="jsonError === true ? 'text-red-400' : 'text-gray-400'"
                class="text-xl animate-spin-pulse"
              />
              <span class="ml-4 text-gray-400">loading...</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import SortTable from '@/services/TableSort.js';
import userStrings from '@/strings/en.js';

export default {
  data() {
    return {
      colors: {
        tr: { NEW: 'bg-red-50', OPEN: 'bg-yellow-50', RESOLVED: 'bg-green-50', },
        td: { NEW: 'border-red-300 hover:bg-red-100', OPEN: 'border-yellow-300 hover:bg-yellow-100', RESOLVED: 'border-green-300 hover:bg-green-100', },
        text: { NEW: 'text-red-800', OPEN: 'text-yellow-800', RESOLVED: 'text-green-800', },
      },
      statuses: { NEW: 'New', OPEN: 'Open', RESOLVED: 'Resolved' },
      direction: { ['finding.domain_name']: null, record: null, finding_status: null, last_updated: 'sort-down' },
      domain_categories: userStrings.DNSTWIST.domain_category,
    };
  },
  props: {
    findings: Array,
    jsonError: Boolean,
    domainCategory: String,
  },
  methods: {
    sort: SortTable,
  },
  created(){
    this.sort('last_updated', this.direction, this.findings);
  },
};
</script>