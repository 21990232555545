<template>
  <div class="max-w-7xl mx-auto mt-8 px-4 pb-8 sm:px-6 lg:px-8">
    <!-- ------------------------------------------ -->
    <!-- Dashboard Content -->
    
    <!-- Findings Summary - displayed as three cards -->
    <FindingsCards :findings="card_findings" :jsonError="this.$store.state.account.appErrorHeading !== null" />

    <!-- Recent Findings table -->
    <NewFindingsTable :findings="new_findings" :jsonError="this.$store.state.account.appErrorHeading !== null" />

    <!-- end Dashboard Content -->
    <!-- ------------------------------------------ -->
  </div>
</template>

<script>
import NewFindingsTable from '@/components/pages/Dashboard/NewFindingsTable.vue';
import FindingsCards    from '@/components/pages/Dashboard/FindingsCards.vue';

// import json from '@/mock_data/response.json';
import { fetchMessage } from '@/services/fetchers';
import FormatDate  from '@/services/DateFormat.js';
import userStrings from '@/strings/en.js';
import { EXTERNAL_API_URL_BASE } from '@/aws-config.js';

export default {
  name: 'Dashboard',
  components: {
    FindingsCards,
    NewFindingsTable,
  },
  data() {
    return {
      // Don't initialize finding counts to 0.
      // Each sub-object in card_findings needs to exist
      // for its card to draw in the Dashboard, but we don't want
      // the card to ever prematurely show "0 new findings" to then
      // update to "20 new findings" once the json fetch completes.
      // Wait until json fetch completes to set to 0.
      card_findings: {
        GITHUB:   {},
        DEHASHED: {},
        DNSTWIST: {},
        FOOTPRINT:{},
      },
      new_findings: null,
    }
  },
  async created() {
    try {
      
      // wait for the json fetch to complete/fail, first
      // no need to define user-facing strings if it fails
      const json = await fetchMessage(`${EXTERNAL_API_URL_BASE}findings`);

      // ---------------------------------------------------------
      
      this.card_findings.GITHUB    = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0}
      this.card_findings.DEHASHED  = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0}
      this.card_findings.DNSTWIST  = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0}
      this.card_findings.FOOTPRINT = {NEW:0, OPEN:0, RESOLVED:0, TOTAL:0}

      this.new_findings = [];

      // loop through json to fill card_findings and new_findings
      // to pass them off to their respective components
      for (const finding of json){

        // ---------------------------------------------------------
        // first, handle card_findings
        // card_findings is used for the Dashboard's "cards"
        //
        // increment this bucket's finding_type count
        this.card_findings[ finding.finding_type ][finding.finding_status] += 1;
        // increment this bucket's overall finding count
        this.card_findings[ finding.finding_type ].TOTAL += 1;


        // ---------------------------------------------------------
        // then, handle new_findings
        //
        // new_findings is used for the dashboard's New Findings table
        //   id:           unique id to be used in the finding url
        //   finding_type: one of ["GITHUB", "DEHASHED", "DNSTWIST"]
        //   feature:      user-facing string, one of ["Secrets", "Domain", "Data Breach"]
        //   source:       user-facing string, one of ["GitHub", "DNSTwist", "Dehashed"]
        //   updated:      datetime, formatted to be human-readable
        //   details:      the entire "finding" object from json
        if (finding.finding_status === 'NEW'){
          this.new_findings.push({
            id:           finding.finding_id,
            finding_type: finding.finding_type,
            feature:      userStrings[finding.finding_type].feature,
            source:       userStrings[finding.finding_type].source,
            last_updated: FormatDate(new Date(finding.last_updated)),
            details:      finding.finding,
          });
        }
      }
    }
    catch(error){
      this.$store.dispatch('account/setAppError', {
        appErrorHeading: 'There was an error retrieving your findings; try refreshing the page.  If the problem persists, let us know.',
        appErrorMessage: null
      });
    }
  }
};
</script>